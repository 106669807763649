import { ChainId } from '@pancakeswap/chains'
import { MTW } from '@pancakeswap/tokens'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { FAST_INTERVAL } from 'config/constants'

// for migration to bignumber.js to avoid breaking changes
export const useMtwPrice = ({ enabled = true } = {}) => {
  const { data } = useQuery<BigNumber, Error>({
    queryKey: ['mtwPrice'],
    queryFn: async () => new BigNumber(await getMtwPriceFromGecko()),
    staleTime: FAST_INTERVAL,
    refetchInterval: FAST_INTERVAL,
    enabled,
  })
  return data ?? BIG_ZERO
}

export const getMtwPriceFromGecko = async () => {
  const address = MTW[ChainId.BSC].address
  const response = await fetch(`https://api.geckoterminal.com/api/v2/simple/networks/bsc/token_price/${address}`)
  const res = await response.json()

  return res.data.attributes.token_prices[address.toLowerCase()]
}
